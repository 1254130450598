import "./App.css";
import Login from "./component/Login/login";
import Signup from "./component/SignUp/signup"
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} /> 
          <Route path="/signup" element={<Signup />} /> 
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
