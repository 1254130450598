import React from "react";
import SignImage from "../../image/Sign_up.png";
import SignBack from "../../image/700.jpg";

const Signup = () => {
  return (
    <div
      className="h-screen w-screen flex flex-col justify-start bg-cover bg-no-repeat bg-center"
      style={{ backgroundImage: `url(${SignBack})` }}
    >
      <div className="flex flex-col justify-between pt-0 ">
        <h1 className="text-[50px] text-center font-semibold mt-6 text-[#B9AC74]">
          Sign up
        </h1>
        <img src={SignImage} className="w-80 h-80 mx-auto mt-2" alt="Logo" />
      </div>
      <div className="flex flex-col items-center">
        {/* Adjusted the layout and added classes for styling */}
        <div className="flex flex-col items-start mb-4">
          <label htmlFor="" className="text-[#B9AC74] font-bold mb-1">
            Username
          </label>
          <input
            type="text"
            className="h-12 w-[275px] bg-[#F9F5F5] border-[#707070] border focus:outline-none rounded-2xl
               placeholder:text-[#606074] text-left pl-3 placeholder-opacity-50"
            placeholder="username"
          />
        </div>
        <div className="flex flex-col items-start mb-4">
          <label htmlFor="" className="text-[#B9AC74] font-bold mb-1">
            Email
          </label>
          <input
            type="email"
            className="h-12 w-[275px] bg-[#F9F5F5] border-[#707070] border focus:outline-none rounded-2xl
               placeholder:text-[#606074] text-left pl-3 placeholder-opacity-50"
            placeholder="email "
          />
        </div>
        <div className="flex flex-col items-start mb-4">
          <label htmlFor="" className="text-[#B9AC74] font-bold mb-1">
            Password
          </label>
          <input
            type="password"
            className="h-12 w-[275px] bg-[#F9F5F5] border-[#707070] border focus:outline-none rounded-2xl
               placeholder:text-[#606074] text-left pl-3 placeholder-opacity-50"
            placeholder="password"
          />
        </div>
        <div className="items-center">
          <button
            className="h-12 w-28 mt-6 text-[#B9AC74] bg-[#F9F5F5] border-[#707070] border focus:outline-none rounded-2xl
               mx-auto font-semibold shadow-current shadow-lg"
          >
            sign up
          </button>
        </div>
      </div>
    </div>
  );
};

export default Signup;
