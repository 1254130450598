import React from 'react';
import Backimage from "../../image/679.jpg";
import LogoinImg from "../../image/Wavy_Tech-28_Single-10.png";
import { Link } from "react-router-dom";

const Login = () => {
    return (
        <div
          className="h-screen w-screen bg-cover bg-no-repeat bg-center"
          style={{ backgroundImage: `url(${Backimage})` }}
        >
          <div className="text-center flex flex-col items-center align-middle h-auto ">
            <h1 className="text-[50px] text-center font-semibold mt-16 text-[#B9AC74]">Log in</h1>
            <img src={LogoinImg} className="w-80 h-80 mx-auto mt-2" alt="Logo" />
            <div className="flex flex-col align-center items-baseline">
              <label htmlFor="" className="text-[#B9AC74] font-bold">Email</label>
              <input type="text" className="h-12 w-[275px] bg-[#F9F5F5] border-[#707070] border focus:outline-none rounded-2xl
               placeholder:text-[#606074] text-left pl-3 placeholder-opacity-95" placeholder="email "/>
    
              <label htmlFor="" className="text-[#B9AC74] mt-3 font-bold">Password</label>
              <input type="password" className="h-12 w-[275px] bg-[#F9F5F5] border-[#707070] border focus:outline-none rounded-2xl
               placeholder:text-[#606074] text-left pl-3 placeholder-opacity-50" placeholder="password"/>
    
              <button className="h-12 w-28 mt-6 text-[#B9AC74] bg-[#F9F5F5] border-[#707070] border focus:outline-none rounded-2xl
               mx-auto font-semibold shadow-current shadow-lg">log in</button>
    
              <Link to="/Signup" className="text-right w-full text-[#B9AC74] font-semibold mt-8">Create account</Link>
            </div>
          </div>
        </div>
    );
}

export default Login;
